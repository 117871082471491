import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Constants from 'src/common/Constants';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {}
});

const Password = ({ className, ...rest }) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    id: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    errors: '',
    detail: '',
    showSuccessAlert: false,
    showErrorAlert: false,
    isLoading: false
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (localStorage.user) {
      const userLocal = JSON.parse(localStorage.user);

      setValues({
        ...values,
        id: userLocal.id,
        email: userLocal.username
      });
    }
  }, []);

  const handlePasswordUpdate = () => {
    setValues({ ...values, isLoading: true });

    const userLocal = JSON.parse(localStorage.user);

    const config = {
      headers: { Authorization: `Bearer ${userLocal.accessToken}` }
    };

    axios
      .put(`${Constants.API_ENDPOINT}/accounts/${values.id}`, values, config)
      .then(response => {
        setValues({
          ...values,
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
          errors: '',
          detail: '',
          showSuccessAlert: true,
          showErrorAlert: false,
          isLoading: false
        });
      })
      .catch(err => {
        console.log(err);

        if (err.status === 500) {
          setValues({
            ...values,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            errors: '',
            detail: '',
            showErrorAlert: true,
            showSuccessAlert: false,
            isLoading: false
          });
        } else {
          setValues({
            ...values,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            errors: err.response.data.errors,
            detail: err.response.data.detail,
            showSuccessAlert: false,
            showErrorAlert: false,
            isLoading: false
          });
        }
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        {values.showSuccessAlert && (
          <Alert severity="success">Operation completed successfully.</Alert>
        )}
        {values.showErrorAlert && (
          <Alert severity="error">
            Error. Something happened on the server!
          </Alert>
        )}
        <CardContent>
          <TextField
            fullWidth
            label="Current Password"
            margin="normal"
            name="currentPassword"
            onChange={handleChange}
            type="password"
            value={values.currentPassword}
            variant="outlined"
          />
          {values.errors && values.errors.CurrentPassword && (
            <Typography style={{ color: 'red' }}>
              {values.errors.CurrentPassword}
            </Typography>
          )}
          <TextField
            fullWidth
            label="New Password"
            margin="normal"
            name="newPassword"
            onChange={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          {values.errors && values.errors.NewPassword && (
            <Typography style={{ color: 'red' }}>
              {values.errors.NewPassword}
            </Typography>
          )}
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="newPasswordConfirmation"
            onChange={handleChange}
            type="password"
            value={values.newPasswordConfirmation}
            variant="outlined"
          />
          {values.errors && values.errors.NewPasswordConfirmation && (
            <Typography style={{ color: 'red' }}>
              {values.errors.NewPasswordConfirmation}
            </Typography>
          )}
          {values.detail && (
            <Typography style={{ color: 'red' }}>{values.detail}</Typography>
          )}
        </CardContent>
        <Divider />
        <Typography style={{ color: 'red' }}></Typography>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            disabled={values.isLoading}
            color="primary"
            variant="contained"
            onClick={handlePasswordUpdate}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
