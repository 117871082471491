import React, { useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import ProfileDetails from 'src/views/account/AccountView/ProfileDetails';
import { useNavigate } from 'react-router-dom';
import Password from 'src/views/settings/SettingsView/Password';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.user || !localStorage.user.length > 0) navigate('/login');
  }, [navigate]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item lg={4} md={6} xs={12}>
            <Profile />
          </Grid> */}
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Password />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
