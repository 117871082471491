import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Constants from 'src/common/Constants';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: '',
    email: '',
    organizationName: '',
    organizationEmail: ''
  });

  useEffect(() => {
    if (localStorage.user) {
      const userLocal = JSON.parse(localStorage.user);

      const config = {
        headers: { Authorization: `Bearer ${userLocal.accessToken}` }
      };

      axios
        .get(`${Constants.API_ENDPOINT}/accounts/${userLocal.id}`, config)
        .then(response => {
          setValues(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="The information can not be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                // helperText="Please specify the first name"
                label="Your Name"
                name="name"
                onChange={handleChange}
                disabled
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Organization Name"
                name="organizationName"
                onChange={handleChange}
                disabled
                value={values.organizationName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Your Email"
                name="email"
                onChange={handleChange}
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Organization Email"
                name="organizationEmail"
                onChange={handleChange}
                disabled
                value={values.organizationEmail}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/* <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAccountUpdate}
          >
            Save details
          </Button>
        </Box> */}
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
